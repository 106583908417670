define('Axelites_Core/js/typesensejs',[
    "jquery",
    "mage/translate",
    "mage/url",
    "js/typesense-instantsearch-adapter.min",
    "js/instantsearch.production.min",
    "Axelites_Core/js/model/ax-core",
    "Magento_Ui/js/modal/modal",
    "domReady!"
], function ($, $t, url, TypesenseInstantSearchAdapter, instantsearch,axCore, modal) {

    return function (config) {
        const typesenseConfig = config.typesenseConfig;
        const storeId = config.storeId;
        const isPopup = config.isPopup;
        const svgSpriteUrl = require.toUrl('images/sprite-svg.svg');
        const categoryId = typeof config.categoryId !== "undefined" ? config.categoryId : '';
        const categoryName = typeof config.categoryName !== "undefined" ? config.categoryName : '';
        const useCategoryCollection = typeof config.useCategoryCollection !== "undefined" ? config.useCategoryCollection : 0;
        let selectedCategoryId = (categoryId != '') ? categoryId : typesenseConfig.default_categoryId;
        var hidePromoOldPrice = typeof config.hidePromoOldPrice !== "undefined" ? config.hidePromoOldPrice : 0;
        const showSpecialOffre = typeof config.showSpecialOffre !== "undefined" ? config.showSpecialOffre : 0;
        const showPriceRefMenzzo = typeof config.showPriceRefMenzzo !== "undefined" ? config.showPriceRefMenzzo : 0;
        const hideDiscountPercentOnPromoTag = typeof config.hideDiscountPercentOnPromoTag !== "undefined" ? config.hideDiscountPercentOnPromoTag : 0;
        const highlightedSelectionReference = typeof config.highlightedSelectionReference !== "undefined" && config.highlightedSelectionReference != 0
            ? config.highlightedSelectionReference
            : null;


        // let defaultSortBy = "_text_match:desc";
        let defaultSortBy = "position:desc";
        // if(!isPopup){
        require(['Axelites_Core/js/axQuickView', 'js/swiped-events.min']);
        // }

        if (typesenseConfig.use_categories_position) {
            selectedCategoryId = typesenseConfig.default_categoryId;
            defaultSortBy = "position_" + selectedCategoryId + ":desc";
        }

        if (typesenseConfig.use_categories_position && (categoryId != '')) {
            defaultSortBy = "position_" + categoryId + ":desc";
        }

        if (categoryId == 61) {
            defaultSortBy = "discountValueForFilter:desc";
        }


        const additionalSearchParams = {
            query_by: "name,categories, sku, matiere_structure_string",
            sort_by: defaultSortBy,
            num_typos: 1,
            typo_tokens_threshold: 1,
            exhaustive_search: false,
            search_cutoff_ms: 5000,
            use_cache: true,
            exclude_fields: "upsell_products",
        }

        // Conditionally add stopwords if storeId is 3
        if (storeId === 3) {
            additionalSearchParams.stopwords = 'stopword_set_' + storeId;
        }

        const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
            server: {
                apiKey: typesenseConfig.api_key,
                nodes: [
                    {
                        host: typesenseConfig.host_name,
                        port: typesenseConfig.port_number,
                        protocol: "https",
                    },
                ],
                connectionTimeoutSeconds: 1,
                numRetries: 8,
            },
            additionalSearchParameters: additionalSearchParams
        });

        let params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        var cssClassPrefix = '#ax_search_page ';
        if (isPopup) {
            cssClassPrefix = '#ax_search_popup ';
        }
        const oldSearchClient = typesenseInstantsearchAdapter.searchClient;

        let collectionName = typesenseConfig.prefix + "_products_" + storeId;


        if (useCategoryCollection) {
            if (typesenseConfig.use_categories_position) {
                collectionName = typesenseConfig.prefix + "_products_" + storeId + '_category_' + typesenseConfig.default_categoryId;
            } else if (categoryId) {
                collectionName = collectionName + '_category_' + categoryId;
            }
        }

        if (!categoryId) {
            collectionName = typesenseConfig.prefix + "_products_" + storeId + '_category_' + typesenseConfig.default_categoryId;
        }

        let collectionNameQueryAnalytics = collectionName + "_query_analytics";

        let canSearch = false;

        let getAllResults = false;
        let searchClient = {
            clearCache: oldSearchClient.clearCache,
            search: (t) => {
                let res;
                try {

                    if (categoryId && (categoryId == 61 || categoryId == 128 || categoryId == 417 || categoryId == 418)) {
                        res = oldSearchClient.search(t);
                    } else {
                        const facetFiltersLength = t.find(item => item.params.facetFilters).params.facetFilters.length;
                        let isFiltering = facetFiltersLength > 1 || t.length > 2 || isSorting;
                        if (categoryId
                            && config.cachedCategoryResults
                            && !isFiltering
                            && t[0]
                            && t[0].params.page === 0
                            && window.history.state === null || (window.history.state && typeof window.history.state[collectionName] !== "undefined" && _.isEmpty(window.history.state[collectionName]) && !isFiltering && t[0] && t[0].params.page === 0)) {
                            res = new Promise((resolve, reject) => {
                                resolve(config.cachedCategoryResults);
                            });
                        } else if (categoryId && config.cachedCategoryResultsPage2 && t[0] && t[0].params.page === 1 && !isFiltering) {
                            res = new Promise((resolve, reject) => {
                                resolve(config.cachedCategoryResultsPage2);
                            });
                        } else {
                            res = oldSearchClient.search(t);
                        }
                    }

                } catch (e) {
                    console.log(e);
                    res = oldSearchClient.search(t);
                }
                return res.then((response) => {
                    response.results[0].hits.map((hit) => {
                        hit['in_page'] = t[0].params.page + 1;
                        return hit;
                    })
                    return response;
                });
            },
            searchForFacetValues: oldSearchClient.searchForFacetValues
        };


        const search = instantsearch({
            searchClient,
            indexName: collectionName,
            routing: {
                router: instantsearch.routers.history({
                    createURL({qsModule, routeState, location}) {
                        if(isPopup) {
                            return location.href;
                        }
                        const baseUrl = location.href.split('?')[0];
                        const queryParameters = Object.assign({}, qsModule.parse(location.search.slice(1)));

                        const currentCustomFilters = search.helper.state.filters || '';
                        const customFiltersMap = {};

                        if (currentCustomFilters) {
                            currentCustomFilters.split(' && ').forEach(filter => {
                                const [key, value] = filter.split(':');
                                customFiltersMap[key] = value;
                            });
                        }

                        if (routeState[collectionName]) {
                            if (Object.keys((routeState[collectionName] && routeState[collectionName].refinementList) || {}).length === 0
                                && !(routeState[collectionName] && routeState[collectionName].range)
                                && !(routeState[collectionName] && routeState[collectionName].hierarchicalMenu)
                                && (!(routeState[collectionName] && routeState[collectionName].page) || routeState[collectionName].page === 1)) {
                                return baseUrl;
                            }

                            if (routeState[collectionName].page && routeState[collectionName].page !== 1) {
                                queryParameters.page = routeState[collectionName].page;
                            } else {
                                delete queryParameters.page;
                            }

                            if (routeState[collectionName].hierarchicalMenu) {

                                Object.entries(routeState[collectionName].hierarchicalMenu).forEach(([filterName, filterValues]) => {

                                    if (filterValues && filterValues.length) {
                                        queryParameters[filterName] = filterValues.join("&");
                                    } else {
                                        delete queryParameters[filterName];
                                    }
                                });
                            }

                            if (routeState[collectionName].refinementList) {
                                Object.entries(routeState[collectionName].refinementList).forEach(([filterName, filterValues]) => {
                                    if (filterValues && filterValues.length) {
                                        queryParameters[filterName] = filterValues;
                                    } else {
                                        delete queryParameters[filterName];
                                    }
                                });
                            }

                            if (routeState[collectionName].range) {
                                Object.entries(routeState[collectionName].range).forEach(([filterName, filterValue]) => {
                                    if (filterValue) {
                                        queryParameters[filterName] = filterValue;
                                    } else {
                                        delete queryParameters[filterName];
                                    }
                                });
                            }
                        }

                        Object.keys(queryParameters).forEach(param => {
                            if (param != 'page') {
                                const existsInRefinementList = routeState[collectionName] &&
                                    routeState[collectionName].refinementList &&
                                    routeState[collectionName].refinementList[param];

                                const existsInRange = routeState[collectionName] &&
                                    routeState[collectionName].range &&
                                    routeState[collectionName].range[param];

                                const existsInHierarchicalMenu = routeState[collectionName] &&
                                    routeState[collectionName].hierarchicalMenu &&
                                    routeState[collectionName].hierarchicalMenu[param];

                                if (existsInHierarchicalMenu) {
                                    const queryValues = queryParameters[param].split('&');
                                    const hierarchicalMenuValues = routeState[collectionName].hierarchicalMenu[param];

                                    const allValuesMatch = queryValues.every(value => hierarchicalMenuValues.includes(value));

                                    if (!allValuesMatch) {
                                        delete queryParameters[param];
                                    }
                                } else if (!existsInRefinementList && !existsInRange && !existsInHierarchicalMenu) {
                                    delete queryParameters[param];
                                }
                            }
                        });


                        const queryString = qsModule.stringify(queryParameters, {
                            addQueryPrefix: true,
                            arrayFormat: 'comma'
                        });

                        return `${baseUrl}${queryString}`;
                    },

                    parseURL({qsModule, location}) {
                        const params = qsModule.parse(location.search.slice(1));
                        const hierarchicalMenu = {};
                        const refinementList = {};
                        const range = {};
                        let page;

                        Object.entries(params).forEach(([key, value]) => {
                            if (value) {
                                if (key == 'page') {
                                    page = parseInt(value);
                                }else if (key.startsWith('categories.')) {

                                    const splitValues = value.split('&');

                                    if (!hierarchicalMenu[key]) {
                                        hierarchicalMenu[key] = splitValues;
                                    } else {
                                        splitValues.forEach(newValue => {
                                            if (!hierarchicalMenu[key].includes(newValue)) {
                                                hierarchicalMenu[key].push(newValue);
                                            }
                                        });
                                    }
                                } else if (key.includes('price') || key === 'final_price') {
                                    range[key] = value;
                                } else {

                                    const values = typeof value === 'string' ? value.split(',').map(item => item.trim()) : [value];

                                    if (key in refinementList && Array.isArray(refinementList[key])) {

                                        values.forEach(val => {
                                            if (!refinementList[key].includes(val)) {
                                                refinementList[key].push(val);
                                            }
                                        });
                                    } else {
                                        refinementList[key] = values;
                                    }
                                }
                            }
                        });

                        let result = {
                            [collectionName]: {
                                hierarchicalMenu,
                                refinementList,
                                range
                            }
                        };

                        if (page) {
                            result[collectionName].page = page;
                        }

                        return result;
                    }
                }),
                stateMapping: {
                    stateToRoute(uiState) {
                        return Object.assign({}, uiState);
                    },
                    routeToState(routeState) {
                        return Object.assign({}, routeState);
                    },
                }
            },
            searchFunction: function (helper) {
                if (getAllResults && !isPopup) {
                    let noResultsForString = noResultsFor.replace('%s', "'" + params.q + "'");
                    $('.catalogsearch-result-index .page-title-wrapper .page-title span').text(noResultsForString);
                    if (!$('.catalogsearch-result-index .page-title-wrapper .typesens-no-result-info').length) {
                        $('.catalogsearch-result-index .page-title-wrapper').append('<div class="typesens-no-result-info"><p>' + noResultsInfoP1 + '</p><p>' + noResultsInfoP2 + '</p></div>');
                    }
                    params.q = '*';
                    helper.state.query = "*"
                    helper.search();
                }

                let customFilter = [];
                if (categoryId) {
                    customFilter.push('categoriesIds:' + categoryId);
                }
                if (params.promo) {
                    let promo = params.promo.split(',').map((p) => p + '%');
                    customFilter.push('soldes:' + JSON.stringify(promo));
                }

                if (highlightedSelectionReference && highlightedSelectionReference != 0 && highlightedSelectionReference != 'undefined')
                {
                    customFilter.push('highlighted_selection:' + highlightedSelectionReference);
                }

                if (typeof params.highlighted_selection !== "undefined" && params.highlighted_selection) {
                    customFilter.push('highlighted_selection:' + params.highlighted_selection);
                }
                if (typeof params.univers_trends !== "undefined" && params.univers_trends) {
                    customFilter.push('univers_trends:' + params.univers_trends);
                }

                if (customFilter.length) {
                    helper.state.filters = customFilter.join(' && ');
                }
                if (!isPopup) {
                    if (params.q) {
                        helper.state.query = params.q;
                    }
                }
                if (!isPopup) {
                    helper.search();
                } else {
                    if (canSearch) {
                        helper.search();
                    }
                }
            },
        });

        $(document).on('click', cssClassPrefix + ' .ais-InfiniteHits-loadPrevious', function (e) {
            const searchParams = new URLSearchParams(window.location.search);

            if (searchParams.has('page')) {
                let currentPage = parseInt(searchParams.get('page'), 10);

                if (currentPage > 1) {
                    const newPage = currentPage - 1;

                    searchParams.set('page', newPage);

                    const newQueryString = searchParams.toString();
                    const newURL = `${window.location.pathname}?${newQueryString}`;

                    window.history.pushState({}, '', newURL);

                }
            }
        });

        // window.amine = search
        var productsString = $t('Results found');
        var productString = $t('Result found');
        var noproductsString = $t('No result');
        var noResultsFor = $t('No results for : %s');
        var noResultsInfoP1 = $t('Try checking your spelling or using more general terms.');
        var noResultsInfoP2 = $t('You can find below the list of all our products.');

        let isSorting = false;
        if (!document.querySelector(cssClassPrefix + ' .categories')) {
            return;
        }
        // Create the render function
        const renderSortBy = (renderOptions, isFirstRender) => {
            const {
                options,
                currentRefinement,
                canRefine,
                refine,
                widgetParams,
            } = renderOptions;
            if (isPopup) return;

            if (isFirstRender) {
                const select = document.createElement('select');
                if (typeof widgetParams.cssClasses !== "undefined" && typeof widgetParams.cssClasses.select !== "undefined") {
                    let classes = widgetParams.cssClasses.select.split(' ');
                    classes.forEach((cssClass) => {
                        select.classList.add(cssClass);
                    });
                }

                select.addEventListener('change', event => {
                    isSorting = true;
                    refine(event.target.value);
                });

                widgetParams.container.appendChild(select);
            }

            const select = widgetParams.container.querySelector('select');
            // select.disabled = !canRefine;

            select.innerHTML = `
    ${options
                .map(
                    option => `
          <option
            value="${option.value}"
            ${option.value === currentRefinement ? 'selected' : ''}
          >
            ${option.label}
          </option>
        `
                )
                .join('')}
  `;
        };

        const customSortBy = instantsearch.connectors.connectSortBy(renderSortBy);

        function debounce(func, wait) {
            let timeout;
            return function(...args) {
                const context = this;
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(context, args), wait);
            };
        }

        const renderSearchBox = (renderOptions, isFirstRender) => {
            const { query, refine, clear, isSearchStalled, widgetParams } = renderOptions;

            if (isFirstRender && isPopup) {
                const input = document.querySelector('#searchbox .ais-SearchBox-input');
                input.classList.add('ais-SearchBox-input');
                input.classList.add('form-control');
                input.classList.add('form-control-sm');
                input.classList.add('border');
                input.classList.add('border-light');
                input.classList.add('text-dark');
                input.setAttribute('placeholder', $t('Search entire store here...'));
                const debouncedRefine = debounce(refine, 400);
                const debouncedGetQuerySuggestions = debounce(getQuerySuggestions, 400);

                input.addEventListener('input', event => {
                    canSearch = true;
                    debouncedRefine(event.target.value);
                    debouncedGetQuerySuggestions(event.target.value, refine);
                });

                input.setAttribute('value', widgetParams.container.querySelector('form .ais-SearchBox-input').value);
                if (input.value !== '') {
                    input.focus();
                    const end = input.value.length;
                    input.setSelectionRange(end, end);
                    canSearch = true;
                    debouncedRefine(input.value);
                    debouncedGetQuerySuggestions(input.value, refine);

                    var position = $('#searchbox .ais-SearchBox-input').offset();
                    var height = $('#searchbox .ais-SearchBox-input').outerHeight();
                    var top = position.top + height;
                    $('#ax_search_popup').css({ left: position.left, top: top, zIndex: 9 });
                    $('#ax_search_popup').fadeIn();
                } else {
                    $('#ax_search_popup').fadeOut();
                }
            }
        };

        const customSearchBox = instantsearch.connectors.connectSearchBox(renderSearchBox);



        /** create ax cusyom input attribute search **/
            // 1. Create a render function
        const renderAxRefinementList = (renderOptions, isFirstRender) => {
                const {
                    items,
                    isFromSearch,
                    refine,
                    isShowingMore,
                    canToggleShowMore,
                    searchForItems,
                    toggleShowMore,
                    widgetParams,
                } = renderOptions;

                var serachText = '';
                if (isFirstRender) {
                    const input = document.createElement('input');
                    const ul = document.createElement('ul');
                    const button = document.createElement('button');
                    input.classList.add('ais-SearchBox-input');
                    input.setAttribute('placeholder', $t('Search...'));
                    ul.classList.add('ais-RefinementList-list');
                    ul.classList.add('list-unstyled');
                    button.textContent = $t('See more');
                    button.classList.add('ais-RefinementList-showMore');

                    input.addEventListener('input', event => {
                        serachText = event.currentTarget.value;
                        searchForItems(event.currentTarget.value);
                    });

                    button.addEventListener('click', () => {
                        toggleShowMore();
                    });

                    if (widgetParams.container) {
                        widgetParams.container.querySelector(cssClassPrefix + 'form').appendChild(input);
                        widgetParams.container.querySelector(cssClassPrefix + '.ais-RefinementList').appendChild(ul);
                        widgetParams.container.querySelector(cssClassPrefix + '.ais-RefinementList').appendChild(button);
                    }

                    $(document).on('axTypesenseSearchComplete', function () {
                        if (serachText) {
                            searchForItems(serachText);
                        }
                    });

                }
                if (widgetParams.container) {
                    const input = widgetParams.container.querySelector('input');
                    let sortedItems = items;
                    if (widgetParams.attribute == 'disponibilite_menzzo') {
                        sortedItems = _.sortBy(sortedItems, function (it) {
                            if (_.contains(['Une semaine', 'Eine Woche', 'Una semana', 'Een week', 'Una settimana'], it.label)) {
                                return '2';
                            }
                            if (_.contains(['En stock', 'In Stock', 'Auf Lager', ''], it.label)) {
                                return '0';
                            }
                            if (it.label.includes('+')) {
                                return '99';
                            }
                            return it.label;
                        })
                    }
                    if (widgetParams.attribute === 'soldes' && Array.isArray(items) && items.length > 0) {
                        sortedItems = items.slice().sort((a, b) => {
                            const valueA = parseInt(a.value.replace('%', ''), 10);
                            const valueB = parseInt(b.value.replace('%', ''), 10);
                            return valueB - valueA;
                        });
                    }
                    widgetParams.container.querySelector('ul').innerHTML = sortedItems
                        .map(
                            item => `
                            <li>
                              <div>
                                <label class="ais-RefinementList-label d-flex align-items-center">
                                  <input type="checkbox" class="ais-RefinementList-checkbox me-2" value="${item.label}" ${item.isRefined ? 'checked' : ''}>
                                  <span class="ais-RefinementList-labelText">${item.label}</span>
                                  <span class="ais-RefinementList-count badge text-dark-2 ms-2">${item.count}</span>
                                </label>
                              </div>
                            </li>
      `
                        )
                        .join('');

                    [...widgetParams.container.querySelectorAll('input:not(.ais-SearchBox-input)')].forEach(element => {
                        if (isPopup){
                            element.addEventListener('change', event => {
                                event.preventDefault();
                                refine(event.currentTarget.value);
                                $('body').addClass('no-scroll-mobile').removeClass('scroll-mobile');
                            });
                        } else{
                            element.addEventListener('click', event => {
                                event.preventDefault();
                                refine(event.currentTarget.value);
                            });
                        }

                    });

                    const button = widgetParams.container.querySelector('button');
                    button.disabled = !canToggleShowMore;
                    input.disabled = !(widgetParams.searchable);
                    button.textContent = isShowingMore ? $t('See less') : $t('See more');
                }

            };


        // 2. Create the custom widget
        const axRefinementList = instantsearch.connectors.connectRefinementList(
            renderAxRefinementList
        );

        let isFirstRender = true;

        /**
         * Forcing translations manually so the json-translations can grab it manually
         */
        $t('colorfamily');
        $t('categories');
        $t('soldes');
        $t('SOLDES');
        $t('disponibilite_menzzo');
        $t('forme');
        $t('matiere_structure');
        $t('bed_size');
        $t('size');
        $t('product_universes');
        $t('nb_places');
        $t('nb_cutlery');
        $t('nb_products');
        $t('nb_persons');
        $t('lighting');
        $t('angle');
        $t('diameter');
        $t('bipolart_type');
        $t('largeur');
        $t('profondeur');
        $t('hauteur');
        $t('extensible');
        $t('hauteur_ajustable');
        $t('espace_rangement');
        $t('gigogne');
        $t('assise_matelassee');
        $t('dossier_matelasse');
        $t('assise_pivotante');
        $t('sofa_type');
        $t('forme_canape');
        $t('armchair_type');
        $t('dimension_sommier');
        $t('bed_elements');
        $t('bed_type');
        $t('bed_rangement_type');
        $t('fonctions');
        $t('for_bed');
        $t('rangement_type');
        $t('for_angle');
        $t('rangement_equipement');
        $t('rangement_ouverture');
        $t('approximate_width');
        $t('approximate_length');
        $t('approximate_height');
        $t('type_douille');
        $t('fournie');
        $t('entretien');
        $t('emplacement');
        $t('specificity');
        $t('facade_type');
        $t('is_electrical');
        $t('power_watts');
        $t('type_of_product');
        $t('sold_with');
        $t('type_attachment');
        $t('type_rideau');
        $t('motif_rideau');
        $t('piece');
        $t('taille');
        $t('nb_pieces');
        $t('mattress_type');
        $t('destination');
        $t('coussin_type');
        $t('removable');
        $t('luminous');
        $t('theme');
        $t('thickness');
        $t('thickness_cm');
        $t('cutlery_type');
        $t('final_price');
        $t('is_free_shipping');
        $t('capacity');
        $t('type_ombrage');
        $t('type_structure');
        $t('type_accessoire');
        $t('pliant');
        $t('No');
        $t('Yes');
        $t('categories.lvl0');
        $t('categories.lvl1');
        $t('categories.lvl2');


        // Create the render function
        const createDataAttribtues = refinement =>
            Object.keys(refinement)
                .map(key => `data-${key}="${refinement[key]}"`)
                .join(' ');

        const renderListItem = item => `
                                          <li class="ais-CurrentRefinements-item">
                                            <span class="ais-CurrentRefinements-label">${$t(item.label)} :</span>
                                              ${item.refinements
            .map(
                refinement =>
                    `<span class="ais-CurrentRefinements-${item.attribute}">
                                                      <span>${refinement.label}</span>
                                                      <button class="ais-CurrentRefinements-delete"${createDataAttribtues(refinement)}>X</button>
                                                    </span>`
            )
            .join('')}
                                          </li> `;

        const renderCurrentRefinements = (renderOptions, isFirstRender) => {
            const {items, refine, widgetParams} = renderOptions;

            if (!isPopup) {
                widgetParams.container.innerHTML = `
                                            <ul>
                                              ${items.map(renderListItem).join('')}
                                            </ul>`;

                [...widgetParams.container.querySelectorAll('#ax_search_page button.ais-CurrentRefinements-delete')].forEach(element => {
                    element.addEventListener('click', event => {
                        const item = Object.keys(event.currentTarget.dataset).reduce(
                            (acc, key) => {
                                acc[key] = event.currentTarget.dataset[key];
                                return acc;
                            },
                            {}
                        );
                        refine(item);
                    });
                });
            }

        };

        // Create the custom widget
        const customCurrentRefinements = instantsearch.connectors.connectCurrentRefinements(
            renderCurrentRefinements
        );

        let widgets = [
            customSearchBox({
                container: document.querySelector('#searchbox'),
            }),
            instantsearch.widgets.hierarchicalMenu({
                container: document.querySelector(cssClassPrefix + '.categories'),
                showParentLevel: true,
                rootPath: categoryName,
                attributes: [
                    'categories.lvl0',
                    'categories.lvl1',
                    'categories.lvl2',
                ],
                cssClasses: {
                    showMore: 'btn btn-secondary btn-sm',
                    list: 'list-unstyled',
                    childList: 'ms-4',
                    count: 'badge text-dark-2 ms-2',
                    link: 'text-dark text-decoration-none',
                    selectedItem: 'text-primary fw-bold',
                    parentItem: 'text-dark fw-bold',
                },
            }),
            instantsearch.widgets.infiniteHits({
                container: cssClassPrefix + ".infinite-hits",
                showPrevious: true,
                templates: {
                    item: function (product) {
                        let param = new Proxy(new URLSearchParams(window.location.search), {
                            get: (searchParams, prop) => searchParams.get(prop),
                        });
                        product.product_page = param[collectionName + '[page]'];
                        return generateProductWrapHtml(product);
                    },
                    empty: function () {
                        if (!isPopup && $('body').hasClass('catalogsearch-result-index')) {
                            getAllResults = true;
                            search.helper.search();
                        }
                    },
                    showMoreText: $t('See more products'),
                    showPreviousText: $t('See less products'),
                },
                cssClasses: {
                    list: 'list-unstyled grid-container',
                    item: 'd-flex flex-column search-result-card mb-1 me-1',
                    loadMore: 'btn btn-primary mx-auto d-block mt-4 ' + ($(cssClassPrefix + '.infinite-hits').hasClass('popup') ? 'hidden' : ''),
                    loadPrevious: 'btn btn-primary mx-auto d-block mt-4 ' + ($(cssClassPrefix + '.infinite-hits').hasClass('popup') ? 'hidden' : ''),
                    disabledLoadMore: 'btn-dark' + ($(cssClassPrefix + '.infinite-hits').hasClass('popup') ? 'hidden' : ''),
                    disabledLoadPrevious: 'btn-dark' + ($(cssClassPrefix + '.infinite-hits').hasClass('popup') ? 'hidden' : ''),
                },
            }),
            instantsearch.widgets.hitsPerPage({
                container: cssClassPrefix + '.hits-per-page',
                items: [
                    {label: isPopup ? '12 per page' : '20 per page', value: isPopup ? 12 : 20, default: true},
                ],
            }),
            instantsearch.widgets.stats({
                container: cssClassPrefix + '.stats',
                templates: {
                    text: `
                          {{#hasNoResults}} ${noproductsString}{{/hasNoResults}}
                          {{#hasOneResult}}1 ${productString}{{/hasOneResult}}
                          {{#hasManyResults}}{{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}} <span>${productsString}</span>{{/hasManyResults}}
                `,
                },
                cssClasses: {
                    text: 'small',
                },
            }),
            customSortBy({
                container: document.querySelector('#sort-by'),
                items: [
                    {label: $t('Sort'), value: collectionName},
                    {label: $t('Price asc'), value: collectionName + '/sort/final_price:asc'},
                    {label: $t('Price desc'), value: collectionName + '/sort/final_price:desc'},
                    {label: $t('New arrival'), value: collectionName + '/sort/product_id_sort:desc'},
                    {label: $t('Best sellers'), value: collectionName + '/sort/best_sellers:desc'},
                ],
                cssClasses: {
                    select: 'form-select form-select-sm border-none text-black',
                },
            }),
            instantsearch.widgets.clearRefinements({
                container: cssClassPrefix + '.clear-refinements',
                templates: {
                    resetLabel: $t('Reset'),
                },
                cssClasses: {
                    button: 'btn red',
                },
            }),
            customCurrentRefinements({
                container: document.querySelector('#current-refinements'),
            }),
        ];
        if (!isPopup) {
            widgets.push(
                instantsearch.widgets.hierarchicalMenu({
                    container: document.querySelector(cssClassPrefix + '.categories_new'),
                    showParentLevel: true,
                    rootPath: categoryName,
                    attributes: [
                        'categories.lvl0',
                        'categories.lvl1',
                        'categories.lvl2',
                    ],
                    cssClasses: {
                        showMore: 'btn btn-secondary btn-sm',
                        list: 'list-unstyled',
                        childList: 'ms-4',
                        count: 'badge text-dark-2 ms-2',
                        link: 'text-dark text-decoration-none',
                        selectedItem: 'text-primary fw-bold',
                        parentItem: 'text-dark fw-bold',
                    },
                })
            );
        }
        if (categoryId) {
            widgets.push(instantsearch.widgets.clearRefinements({
                container: '.typesence-category-page .clear-soldes-refinement',
                includedAttributes: ['soldes'],
            }));
        }
        let allRefinementList = [
            // {
            //     limit: 6,
            //     showMore: true,
            //     showMoreLimit: 200,
            //     searchable: true,
            //     container: document.querySelector(cssClassPrefix + '.categories'),
            //     attribute: 'categories',
            //     searchablePlaceholder: $t('Search...'),
            //     sortBy: categoryId ? ['name:asc', 'count:desc'] : ['count:desc', 'name:asc'],
            // },
            {
                limit: 8,
                showMore: true,
                showMoreLimit: 100,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.soldes'),
                attribute: 'soldes',
                sortBy: ['name:desc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.colorfamily'),
                attribute: 'colorfamily',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 8,
                showMore: true,
                showMoreLimit: 100,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.disponibilite_menzzo'),
                attribute: 'disponibilite_menzzo',
                sortBy: ['name:asc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.forme'),
                attribute: 'forme',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.matiere_structure'),
                attribute: 'matiere_structure',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.bed_size'),
                attribute: 'bed_size',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.size'),
                attribute: 'size',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.product_universes'),
                attribute: 'product_universes',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.nb_places'),
                attribute: 'nb_places',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.nb_cutlery'),
                attribute: 'nb_cutlery',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.nb_products'),
                attribute: 'nb_products',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.nb_persons'),
                attribute: 'nb_persons',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.lighting'),
                attribute: 'lighting',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.angle'),
                attribute: 'angle',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.diameter'),
                attribute: 'diameter',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.bipolart_type'),
                attribute: 'bipolart_type',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.extensible'),
                attribute: 'extensible',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.hauteur_ajustable'),
                attribute: 'hauteur_ajustable',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.espace_rangement'),
                attribute: 'espace_rangement',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.rangement_type'),
                attribute: 'rangement_type',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.rangement_equipement'),
                attribute: 'rangement_equipement',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.rangement_ouverture'),
                attribute: 'rangement_ouverture',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.gigogne'),
                attribute: 'gigogne',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.assise_matelassee'),
                attribute: 'assise_matelassee',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.dossier_matelasse'),
                attribute: 'dossier_matelasse',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.assise_pivotante'),
                attribute: 'assise_pivotante',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.sofa_type'),
                attribute: 'sofa_type',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.forme_canape'),
                attribute: 'forme_canape',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.armchair_type'),
                attribute: 'armchair_type',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.dimension_sommier'),
                attribute: 'dimension_sommier',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.bed_elements'),
                attribute: 'bed_elements',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.bed_type'),
                attribute: 'bed_type',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.for_bed'),
                attribute: 'for_bed',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.for_angle'),
                attribute: 'for_angle',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.type_douille'),
                attribute: 'type_douille',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.fournie'),
                attribute: 'fournie',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.entretien'),
                attribute: 'entretien',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.is_electrical'),
                attribute: 'is_electrical',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.type_attachment'),
                attribute: 'type_attachment',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.type_rideau'),
                attribute: 'type_rideau',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.motif_rideau'),
                attribute: 'motif_rideau',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.nb_pieces'),
                attribute: 'nb_pieces',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.piece'),
                attribute: 'piece',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.mattress_type'),
                attribute: 'mattress_type',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.coussin_type'),
                attribute: 'coussin_type',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.removable'),
                attribute: 'removable',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.luminous'),
                attribute: 'luminous',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.bed_rangement_type'),
                attribute: 'bed_rangement_type',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.fonctions'),
                attribute: 'fonctions',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.approximate_width'),
                attribute: 'approximate_width',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.approximate_length'),
                attribute: 'approximate_length',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.approximate_height'),
                attribute: 'approximate_height',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.emplacement'),
                attribute: 'emplacement',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.specificity'),
                attribute: 'specificity',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.facade_type'),
                attribute: 'facade_type',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.type_of_product'),
                attribute: 'type_of_product',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.sold_with'),
                attribute: 'sold_with',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.destination'),
                attribute: 'destination',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: true,
                container: document.querySelector(cssClassPrefix + '.theme'),
                attribute: 'theme',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.photo_dimensions'),
                attribute: 'photo_dimensions',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 200,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.photo_dimensions'),
                attribute: 'photo_dimensions',
                searchablePlaceholder: $t('Search...'),
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.cutlery_type'),
                attribute: 'cutlery_type',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.is_free_shipping'),
                attribute: 'is_free_shipping',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.capacity'),
                attribute: 'capacity',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.type_ombrage'),
                attribute: 'type_ombrage',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.type_structure'),
                attribute: 'type_structure',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.type_accessoire'),
                attribute: 'type_accessoire',
                sortBy: ['name:asc', 'count:desc'],
            },
            {
                limit: 6,
                showMore: true,
                showMoreLimit: 100,
                searchable: false,
                container: document.querySelector(cssClassPrefix + '.pliant'),
                attribute: 'pliant',
                sortBy: ['name:asc', 'count:desc'],
            },

        ];
        let allRangeSliderList = [
            {
                container: document.querySelector(cssClassPrefix + '.largeur'),
                attribute: 'largeur',
            },
            {
                container: document.querySelector(cssClassPrefix + '.profondeur'),
                attribute: 'profondeur',
            },
            {
                container: document.querySelector(cssClassPrefix + '.hauteur'),
                attribute: 'hauteur',
            },
            {
                container: document.querySelector(cssClassPrefix + '.final_price'),
                attribute: 'final_price',
            },
            {
                container: document.querySelector(cssClassPrefix + '.power_watts'),
                attribute: 'power_watts',
            },
            {
                container: document.querySelector(cssClassPrefix + '.thickness_cm'),
                attribute: 'thickness_cm',
            },
        ]

        const duplicatedRefinementList = allRefinementList.map(item => {
            let newItem = {};
            for (let key in item) {
                if (item.hasOwnProperty(key)) {
                    newItem[key] = item[key];
                }
            }
            newItem.container = document.querySelector(cssClassPrefix + '.' + item.attribute + '_new');
            return newItem;
        });

        const concatenatedRefinementList = allRefinementList.concat(duplicatedRefinementList);

        const duplicatedRefinementSliderList = allRangeSliderList.map(item => {
            let newItem = {};
            // Copy each property from item to newItem
            for (let key in item) {
                if (item.hasOwnProperty(key)) {
                    newItem[key] = item[key];
                }
            }
            // Add the container property
            newItem.container = document.querySelector(cssClassPrefix + '.' + item.attribute + '_new');
            return newItem;
        });
        const concatenatedSliderList = allRangeSliderList.concat(duplicatedRefinementSliderList);

        concatenatedRefinementList.forEach((item) => {
            if (((typesenseConfig.allowed_attributes).length > 0) && (typesenseConfig.allowed_attributes).includes(item.attribute)) {
                // if((typesenseAttributes.length > 0 && typesenseAttributes.includes(item.attribute)) || typesenseAttributes.length == 0){
                widgets.push(axRefinementList(item));
                // }
            }
        });

        concatenatedSliderList.forEach((item) => {
            if (((typesenseConfig.allowed_attributes).length > 0) && (typesenseConfig.allowed_attributes).includes(item.attribute) && item.container) {
                widgets.push(instantsearch.widgets.rangeSlider(item));
            }
        });

        search.addWidgets(widgets);
        let initialFacetsStats = {};
        let oldFacetsStats = {};
        search.on('render', function () {
            let helper = search.helper;
            if (helper.lastResults !== null) {
                const currentFacetsStats = helper.lastResults.facets_stats;
                if (isPopup) {
                    if (Object.keys(initialFacetsStats).length === 0) {
                        initialFacetsStats = Object.assign({}, currentFacetsStats);
                        oldFacetsStats = initialFacetsStats
                    } else {
                        const diff = compareFacetsStats(currentFacetsStats, oldFacetsStats);
                        oldFacetsStats = currentFacetsStats
                        if (diff && !isFirstRender) {
                            $('#ax_search_popup .typesense-sorting-results').scrollTop(0);
                        }
                    }
                }
            }

            $(document).trigger('axTypesenseSearchComplete');
            if ($(cssClassPrefix + '.ais-InfiniteHits--empty').length > 0) {
                $(cssClassPrefix + '.typesense-filters').css('display', 'none');
                $(cssClassPrefix + '.typesense-filters-top').css('display', 'none');
                if(isPopup){
                    $(cssClassPrefix + '.typesense-sorting-results').removeClass('col-md-9').addClass('col-md-12');
                }
                if ($('body').hasClass('catalogsearch-result-index')) {
                    $('.catalogsearch-result-index .stats-wrap .stats').css('display', 'none');
                    $('.catalogsearch-result-index .mobile-typesense-filter-sort-wrap').css('display', 'none');
                    $('.catalogsearch-result-index .ax-typesense-current-refinements').css('display', 'none');
                    $(cssClassPrefix + '.hidden-no-results').css('display', 'none');
                    $(cssClassPrefix + '.typesense-sorting-results').css('display', 'block');
                }
            } else {
                if(isPopup){
                    $(cssClassPrefix + '.typesense-sorting-results').removeClass('col-md-12').addClass('col-md-9');
                }
                if ($('body').hasClass('catalogsearch-result-index')) {
                    $('.catalogsearch-result-index .stats-wrap .stats').css('display', 'block');
                    $('.catalogsearch-result-index .mobile-typesense-filter-sort-wrap').css('display', 'block');
                    $('.catalogsearch-result-index .ax-typesense-current-refinements').css('display', 'block');
                }
                $(cssClassPrefix + '.typesense-sorting-results').css('display', 'block');

                if ($(window).width() > 767) {
                    $(cssClassPrefix + '.typesense-filters').css('display', 'block');
                    $(cssClassPrefix + '.typesense-filters-top').css('display', 'flex');

                } else {
                    $(cssClassPrefix + '#mobile-typesense-filter').css('display', 'block');
                }
            }

            $('.typesense-filters .typesense-filter, .typesense-filters-top .typesense-filter').css('display', 'block');


            function toggleTypesenseFilter(selector) {
                $(selector).each(function () {
                    var listLength = $(this).children("li").length;

                    // Masquer ou afficher le filtre parent en fonction du nombre d'éléments li
                    $(this).closest('.typesense-filter').css('display', listLength < 1 ? 'none' : 'block');

                    // Afficher le dropdown si des éléments existent
                    if (listLength >= 1) {
                        $('.dropdown-filter').css('display', 'block');
                    }
                });
            }
            toggleTypesenseFilter('.typesense-filter ul.ais-RefinementList-list');
            toggleTypesenseFilter('.typesense-filter .soldes_new');

            var emptyFacetSelector = ['.ais-RangeSlider--disabled', '.ais-HierarchicalMenu--noRefinement'];
            emptyFacetSelector.forEach(function (emptyFacet) {
                var emptyFacets = document.querySelectorAll(emptyFacet);
                emptyFacets.forEach(function (elem) {
                    if (isPopup) {
                        elem.parentNode.parentNode.style.display = 'none';
                    } else {
                        elem.parentNode.parentNode.parentNode.style.display = 'none';
                        elem.parentNode.parentNode.parentNode.parentNode.style.display = 'none';
                    }
                });
            });

            if ($(window).width() < 768) {
                $('#filter-popup-wrapper').css('display', 'none');
                $('#html-body').removeClass('no-scroll');
            }
            $('#ax_search_page.search-page #loader').css('display', 'none');
            $('#ax_search_page.search-page .container-fluid.typesense').css('display', 'block');
            if (!isPopup) {
                let availablePromos = [];
                let availablePromosString = '';
                $('#ax_search_page .soldes .ais-RefinementList-label .ais-RefinementList-checkbox').each(function () {
                    let $this = $(this);
                    availablePromos.push($this.val().replace('%', ''));
                });

                if (availablePromos.length > 0) {
                    $('.solde-link').each(function () {
                        let $th = $(this);
                        let show = false;
                        $th.attr('data-promo').split(',').forEach(function (promo) {
                            if (availablePromos.indexOf(promo) > -1) {
                                show = true;
                            }
                        });
                        if (show) {
                            $th.removeClass('hidden');
                        } else {
                            $th.addClass('hidden');
                        }
                    });
                }

                if ($('.solde-link').length == $('.solde-link.hidden').length) {
                    $('.filter-soldes').addClass('hidden');
                } else {
                    $('.filter-soldes').removeClass('hidden');
                }

            }
            $('.swiper-hover-images').each(function () {
                let $this = $(this);
                if (!$this.hasClass('swiper-initialized')) {
                    swiperImage($this.attr('id').replace('typesense-swiper-', ''));
                }
            });
            if (!isPopup) {
                calcColoris();
            }
            if (categoryId == '61') {
                $('.typesence-category-page .categories .ais-RefinementList-checkbox[value="Promo"]').closest('li.ais-RefinementList-item').remove();
            }


            const [entry] = performance.getEntriesByType("navigation");

            if (!isPopup && entry["type"] === "back_forward" && isFirstRender) {
                const url = new URL(window.location);
                let currentProduct = url.searchParams.get(collectionName + '[clickedProductId]');
                if (currentProduct && typeof currentProduct !== "undefined") {
                    axCore.waitForElement('#product-' + currentProduct, scrollToElement.bind(null, '#product-' + currentProduct));
                } else if ($('.search-page .typesense-filter-block').length > 0) {
                    axCore.waitForElement('.search-page .typesense-filter-block', scrollToElement.bind(null, '.search-page .typesense-filter-block'));
                }
            }

            isFirstRender = false;
            if ($(window).width() > 767) {
                try {
                    if($('#filter-popup-wrapper').closest('.modal-content').length){
                        $('#filter-popup-wrapper').modal("closeModal");
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            $('.dropdown-filter-content').removeClass("show");
            if ($('.carousel-trend').length && $(window).width() < 767) {
                require(['flickity'], function (Flickity) {
                    var flickity = new Flickity('.carousel-trend', {
                        ellAlign: 'left',
                        pageDots: false,
                        prevNextButtons: false,
                        lazyLoad: 1,
                        freeScroll: true,
                        contain: true,
                        on: {
                            // Event handler for when Flickity is initialized
                            ready: function() {
                                this.resize()
                            }
                        }
                    });

                });
            }

        });

        search.start();
        if (!isPopup) {
            window.axSearch = search;
            $(document).on('click', '.solde-link', function () {
                $('.clear-soldes-refinement .ais-ClearRefinements-button').trigger('click');
                let $this = $(this);
                let promo = $this.attr('data-promo').split(',');
                $('#ax_search_page .soldes .ais-RefinementList-label .ais-RefinementList-checkbox').each(function () {
                    let $th = $(this);
                    if (promo.indexOf($th.val().replace('%', '')) > -1) {
                        if ($th.is(":checked")) {
                            $this.find('.solde-img').removeClass('active');
                        } else {
                            $('.solde-link').find('.solde-img').not($(this)).removeClass('active');
                            $this.find('.solde-img').addClass('active');
                        }
                        $th.trigger('click');
                    }
                });
            });


            // bipolart category page checkbox action
            if ($('.category_tops_filter').length > 0) {
                try {
                    $(document).on('click', '.category_tops_filter input', function () {
                        let $this = $(this);
                        var filter = $this.attr('data-attr-label');
                        $('#ax_search_page .bipolart .ais-RefinementList-label .ais-RefinementList-checkbox').each(function () {
                            let $th = $(this);
                            if (filter == $th.val()) {
                                $th.trigger('click');
                            }
                        });

                    });
                } catch (e) {

                }
            }
        }
        if (isPopup) {
            $(window).on('scroll', function () {
                var scroll = $(window).scrollTop();
                var height = $(window).height() / 2;
                if (scroll >= height) {
                    $('#ax_search_popup').fadeOut();
                }
            });

            $(document).on('scroll', '#ax_search_popup .typesense-sorting-results', function () {
                if ($(window).width() <= 767) {
                    $('.ais-SearchBox-input').blur();
                }
            });
            $('#ax_search_popup .typesense-sorting-results').on('scroll', _.debounce(function () {
                if ($('#ax_search_popup .typesense-sorting-results .ais-InfiniteHits-loadMore').not('.ais-InfiniteHits-loadMore--disabled').length) {
                    var btnPosition = $('#ax_search_popup .typesense-sorting-results .ais-InfiniteHits-loadMore').not('.ais-InfiniteHits-loadMore--disabled').position().top;
                    if ($('#ax_search_popup .typesense-sorting-results').scrollTop() >= (btnPosition - 500)) {
                        $('#ax_search_popup .typesense-sorting-results .ais-InfiniteHits-loadMore').not('.ais-InfiniteHits-loadMore--disabled').trigger('click');
                    }
                }
            }, 200));

        }
        if (!isPopup) {
            $(window).on('scroll', _.debounce(function () {
                if ($('.search-page .typesense-sorting-results .ais-InfiniteHits-loadMore').not('.ais-InfiniteHits-loadMore--disabled').length) {
                    var btnPosition = $('.search-page .typesense-sorting-results .ais-InfiniteHits-loadMore').not('.ais-InfiniteHits-loadMore--disabled').position().top;
                    if ($(window).scrollTop() >= (btnPosition - 2000)) {
                        $('.search-page .typesense-sorting-results .ais-InfiniteHits-loadMore').not('.ais-InfiniteHits-loadMore--disabled').trigger('click');
                    }
                }

                if (window.screen.width < 768 && $('.search-page .mobile-typesense-filter-sort-wrap').length) {
                    var filterPosition = $('.search-page .mobile-typesense-filter-sort-wrap').position().top;
                    if ($(window).scrollTop() > (filterPosition + 250)) {
                        $('.search-page .mobile-typesense').addClass('fixed-filter');
                        $('.search-page .ax-typesense-current-refinements').addClass('fixed-current-filter');
                    } else if ($(window).scrollTop() < (filterPosition + 250)) {
                        $('.search-page .mobile-typesense').removeClass('fixed-filter');
                        $('.search-page .ax-typesense-current-refinements').removeClass('fixed-current-filter');
                    }
                }
                if (window.screen.width > 768) {
                    var typesenseFilter = $('.btn-sticky').position().top;
                    if ($(window).scrollTop() > (typesenseFilter + 170)) {
                        $('.btn-sticky').addClass('fixed-current-filter');
                    } else if ($(window).scrollTop() < (typesenseFilter + 200)) {
                        $('.btn-sticky').removeClass('fixed-current-filter');
                    }
                }

            }, 200));
        }

        /****** Custom events ******/
        $(document).on('click', '.filter-title-mobile', function (e) {
            e.preventDefault();
            $('#mobile-typesense-filter').trigger('click');
        });

        $(document).on('keyup', '#searchbox .ais-SearchBox-input', function (e) {
            e.preventDefault();
            if (isPopup) {
                var position = $(this).offset();
                var height = $(this).outerHeight();
                var top = position.top + height;
                $('#ax_search_popup').css({left: position.left, top: top, zIndex: 9});
                if ($(this).val() != '') {
                    $('#ax_search_popup').fadeIn();
                } else {
                    $('#ax_search_popup').fadeOut();
                }

                if (!isFirstRender) {
                    $('#ax_search_popup .typesense-sorting-results').scrollTop(0);
                }
            }
        });

        $(document).on('keydown', '#searchbox .ais-SearchBox-input', function (e) {
            if (e.which == 13) {
                e.preventDefault();
                window.location.href = '/catalogsearch/result/?q=' + $(this).val();
            }
        });

        $(document).on('click', '#mobile-typesense-filter', function (e) {
            e.preventDefault();

            var filterPopupWrapper = $(cssClassPrefix + '#filter-popup-wrapper');
            var typesenseFilters = $(cssClassPrefix + '.typesense-filters');
            var dropdownFilterContents = $('#filter-popup-wrapper .dropdown-filter-content');

            if (isPopup) {
                $('body').addClass('has-modal');
            }
            typesenseFilters.removeClass("hidden");
            filterPopupWrapper.css('display', 'block');
            typesenseFilters.css('display', 'block');

            // Show or hide dropdown-filter-content based on checked inputs
            dropdownFilterContents.each(function () {
                $(this).toggleClass("show", $(this).find('input:checked').length > 0);
            });

            // Update dropdown-filter-content visibility on input change
            $('#filter-popup-wrapper').on('change', 'input', function () {
                var dropdownFilterContent = $(this).closest('.dropdown-filter-content');
                dropdownFilterContent.toggleClass('show', dropdownFilterContent.find('input:checked').length > 0);
            });

            $(cssClassPrefix + '.typesense-sorting-results').css('display', 'none');

            $('#html-body').addClass('no-scroll');
        });


        $(document).on('click', '#apply-typesense-filter', function (e) {
            e.preventDefault();
            if (isPopup) {
                $('body').removeClass('has-modal');
            }
            $(cssClassPrefix + '.typesense-filters').css('display', 'none');
            $(cssClassPrefix + '.typesense-filters-top').css('display', 'none');
            $(cssClassPrefix + '.typesense-sorting-results').css('display', 'block');
            if ($(cssClassPrefix + ' .stats-wrap').get(0)) {
                $(cssClassPrefix + ' .stats-wrap').get(0).scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center'
                });
            }
        });

        $(document).on('click', '#close-typesense-filter', function (e) {
            e.preventDefault();
            $(cssClassPrefix + '.ais-ClearRefinements-button').not('.ais-ClearRefinements-button--disabled').trigger('click');
            $(cssClassPrefix + '.typesense-filters').css('display', 'none');
            $(cssClassPrefix + '.typesense-filters-top').css('display', 'none');
            $(cssClassPrefix + '.typesense-sorting-results').css('display', 'block');
            $('#html-body').removeClass('no-scroll');
            if (isPopup) {
                $('body').removeClass('has-modal');
            }
        });

        function hideFilterPopup() {
            try {
                if ($(window).width() > 767) {
                    $('#filter-popup-wrapper').modal("closeModal");
                } else {
                    $('#filter-popup-wrapper').css('display', 'none');
                    $('#html-body').removeClass('no-scroll');
                }
            } catch (e) {
                console.log(e)
            }
        }

        $(document).on('click', '.ais-RefinementList-checkbox', function () {
            hideFilterPopup();
            $('#html-body').removeClass('no-scroll');
        });
        $(document).on('click', '.ais-RefinementList-showMore', function (e) {
            e.stopPropagation();
            $(this).closest('.dropdown-filter-content').addClass("show");
        });

        // Click event on the slider handle
        $(document).on('click', '.rheostat-handle', function (e) {
            e.preventDefault();
            hideFilterPopup();
        });
        // Ensure popup hides during touch move
        $(document).on('touchend', '.rheostat-handle', function (e) {
            e.preventDefault();
            hideFilterPopup();
        });

        if (categoryId && $(window).width() <= 767) {
            $(document).on('click', '.ax-soldes-ete .filter-soldes a.solde-link,.ax-soldes-ete #app-cover li', function (e) {
                e.preventDefault();
                $('html, body').animate({
                    scrollTop: $("#ax_search_page .stats-wrap").offset().top - 110
                }, 400);
            });
        }

        $(document).on('click', '#searchbox .action.ais-SearchBox-form-submit', function (e) {
            e.preventDefault();
            if (!mobileAndTabletCheck()) {
                var value = $('#searchbox .ais-SearchBox-input').val();
                window.location.href = '/catalogsearch/result/?q=' + value;
            }
        });


        $(document).on('click', function (e) {
            var container = $("#ax_search_popup");
            let isInput = $(e.target).is("input");
            if (isPopup) {
                if (!container.is(e.target) && container.has(e.target).length === 0 && !isInput) {
                    container.fadeOut();
                }
            }
        });
        if (!isPopup) {
            $(document).on('click', '.type-sense-relared .show-more-product-colors', function () {
                var id = $(this).closest('.product-color-wrap').attr('id');
                $(this).find(".sprite-svg-icon use").attr("xlink:href", require.toUrl('images/sprite-svg.svg') + '#minus');
                $(this).attr('class', 'hide-more-product-colors');
                $('#' + id).css('overflow', 'visible');
                $('#' + id).css('height', 'auto');
            });

            $(document).on('click', '.type-sense-relared .hide-more-product-colors', function () {
                var id = $(this).closest('.product-color-wrap').attr('id');
                $(this).find(".sprite-svg-icon use").attr("xlink:href", require.toUrl('images/sprite-svg.svg') + '#plus');
                $(this).attr('class', 'show-more-product-colors');
                $('#' + id).css('height', '30px');
                $('#' + id).css('overflow', 'hidden');
            });

            $(document).on("click", ".product-color", function () {
                var id = $(this).attr('data-id');
                var wishlist = parseInt($(this).attr('data-wishlist'));
                var $this = $(this);
                var settings = {
                    "url": "https://" + typesenseConfig.host_name + "/collections/" + typesenseConfig.prefix + "_products_" + storeId + "_category_" + selectedCategoryId + "/documents/search?q=*&query_by=product_id&filter_by=product_id:=" + id,
                    "method": "GET",
                    "timeout": 0,
                    "headers": {
                        "X-TYPESENSE-API-KEY": typesenseConfig.api_key
                    },
                };
                $.ajax(settings).done(function (response) {
                    if (response.found != 0) {
                        var product = response.hits[0].document;
                        var html = generateProductHtml(product, wishlist);
                        $this.closest('li').find('.typesense-item-wrap').html(html);
                        if (!isPopup) {
                            if (window.screen.width > 767) {
                                swiperImage(product.product_id);
                            }
                            if (window.screen.width < 768) {
                                swiperImage(product.product_id);
                            }
                        }
                    }
                });
            });

            if (!isPopup) {
                $(document).on('click', cssClassPrefix + ' .ax-typesense-product-url', function (e) {
                    let page = $(this).attr('data-ax-page-number');
                    let historyState = window.history.state;
                    if (historyState && typeof historyState[collectionName] !== "undefined" && page != 'null') {
                        historyState[collectionName]['page'] = page;
                        historyState[collectionName]['clickedProductId'] = $(this).attr('data-product-id');
                        let newHistoryState = $.param(historyState);
                        history.scrollRestoration = 'manual';
                        history.pushState({}, null, window.location.pathname + '?' + newHistoryState);
                    }
                });
            }

            // $(document).on('click', cssClassPrefix+' .ais-InfiniteHits-loadPrevious', function (e){
            //     e.preventDefault();
            //     const url = new URL(window.location);
            //     let currentPage = url.searchParams.get(collectionName+'[page]');
            //     if(currentPage && typeof currentPage !== "undefined"){
            //         let previousPage = currentPage-1;
            //         url.searchParams.set(collectionName+'[page]', previousPage);
            //         window.history.replaceState(null, '', url.toString());
            //     }
            // });


            /**** Custom functions *****/
            /**
             *
             */
            function calcColoris() {
                $(cssClassPrefix + ".search-result-card .product-color-wrap").each(function () {
                    var width = $(this).width();
                    var visibleItems = Math.trunc(width / 34);
                    var id = $(this).attr('id');
                    var numItems = $(this).find('.product-color-wrapper').length;
                    if (numItems > visibleItems) {
                        $(this).css('padding-right', '30px');
                        $(this).find('.show-more-product-colors').css('display', 'block');
                    }
                });
            }
        }

        /**
         *
         * @param product
         */
        function generateProductWrapHtml(product) {
            var relatedProductsHtml = '';
            let related = typeof product.related_products !== "undefined" ? product.related_products : '[]';
            var relatedProducts = JSON.parse(related);
            if (relatedProducts.length > 0 && !isPopup) {
                relatedProductsHtml += `<div class="product-color-wrap" id="product-color-wrap-${product.product_id}">`;
                relatedProducts.forEach(related => {
                    var spanClass = related.colorClass;
                    var spanStyle = '';
                    var colorSwitchStyle = '';
                    if (related.productColor && related.firstColor && related.productColor == 'Multicolore') {
                        spanClass += ' switch-multicolor';
                        colorSwitchStyle = `<style>
                                            .switch-multicolor.typesense-catalog-product-color-switch-${related.id}:before {
                                            background: linear-gradient(217deg, rgba(255, 0, 0, .8), rgba(255, 0, 0, 0) 70.70999999999999%), linear-gradient(127deg, rgba(0, 255, 0, .8), rgba(0, 255, 0, 0) 70.70999999999999%), linear-gradient(336deg, rgba(0, 0, 255, .8), rgba(0, 0, 255, 0) 70.70999999999999%);
                                        }
                                        </style>`
                    } else if (related.secondColor) {
                        spanClass += ' switch-bicolor';
                        colorSwitchStyle = `<style>
                                            .switch-bicolor.typesense-catalog-product-color-switch-${related.id}{
                                            background: -moz-linear-gradient(-45deg,  ${related.firstColor} 0%, ${related.firstColor}  50%, ${related.secondColor}  50%, ${related.secondColor}  50%,  ${related.secondColor}  100%) !important;
                                            background: -webkit-linear-gradient(-45deg, ${related.firstColor} 0%, ${related.firstColor}  50%, ${related.secondColor}  50%, ${related.secondColor} 50%, ${related.secondColor} 100%) !important;
                                            background: linear-gradient(135deg, ${related.firstColor} 0%, ${related.firstColor}  50%, ${related.secondColor}  50%, ${related.secondColor} 50%, ${related.secondColor} 100%)  !important;
                                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${related.firstColor}', endColorstr='${related.secondColor}',GradientType=1 ) !important;
                                        }
                                        </style>`
                    }
                    if (related.firstColor) {
                        spanStyle = `style="background: ${related.firstColor}"`;
                    }
                    relatedProductsHtml +=
                        `
                    ${colorSwitchStyle}
                    <span class="product-color-wrapper">
                        <span
                            class="${spanClass} typesense-catalog-product-color-switch typesense-catalog-product-color-switch-${related.id}" data-wishlist="0" ${spanStyle}
                            data-id="${related.id}">
                        </span>
                    </span>`;
                });
                relatedProductsHtml +=
                    `<span class="show-more-product-colors">
                    <svg class="icon sprite-svg-icon">
                        <use xlink:href="${require.toUrl('images/sprite-svg.svg')}#plus"></use>
                    </svg>
                </span>`;
                relatedProductsHtml += `</div>`;
            }
            var html = `<div class="typesense-product-wrap">
            <div class="typesense-item-wrap">`;
            html += generateProductHtml(product);
            html += `</div>`;
            if (!isPopup) {
                html += `
             <div class="mt-1 type-sense-relared">
                    ${relatedProductsHtml}
             </div>`;
            }
            html += `</div>`;
            return html;
        }

        /**
         *
         * @param product
         * @param wishlist
         * @returns {string}
         */

        function generateProductHtml(product, wishlist = 0) {

            hidePromoOldPrice = product.hidePromoOldPrice;
            var quickView = '';
            var wishlistListing = '';
            var soldeTag = '';
            var productSecondImage = '';
            var freeShipping = '';
            var iconFavoris = wishlist > 0 ? 'icon_like_red2' : 'icon_like';
            var quickViewLabel = $t('Quick View');

            /***Product Images Swiper**/
            var productImagesHtml = '';
            let images = typeof product.media_gallery_images !== "undefined" ? product.media_gallery_images : '[]';
            var productImages = JSON.parse(images);

            if (productImages.length > 0) {
                productImages = productImages.slice(0, 6);
                productImagesHtml += `<div class="swiper-hover-images" id="typesense-swiper-${product.product_id}" data-flickity='{"lazyLoad": 1, "cellAlign": "left"}'>`;
                productImagesHtml += `<div class="carousel carousel-${product.product_id} carousel-hover-image carousel-lazyload-2x-is-selected">`;
                productImages.forEach(image => {
                    let imageUrl = image.url;

                    if (imageUrl.startsWith('data:image/')) {
                        return;
                    }
                    let webpUrl = imageUrl.includes('?') ? imageUrl + '&fm=webp' : imageUrl;
                    let lazySrcset = "https://cdn-img.menzzo.com/media/lazysizes/menzzo_big.jpg?twic=v1/cover=288x288"
                    let picture = '';
                    if (isSafari13()) {
                        let webpUrl2 = webpUrl + '?twic=v1/resize=288'
                        picture = `<picture>
                                <img class="lazyload" src="${webpUrl2}"  width="430" height="430" alt="image">
                      </picture>`
                    } else {
                        picture = `<picture>
                                 <source data-flickity-lazyload-srcset="${webpUrl}" data-srcset="${webpUrl}?twic=v1/resize=288"  type="image/webp">
                                <img class="lazyload" data-flickity-lazyload="${webpUrl}" srcset="${lazySrcset}"  src="${lazySrcset}" data-src="${imageUrl}?twic=v1/resize=288" width="288" height="288" alt="image">
                            </picture>`
                    }

                    productImagesHtml +=
                        `<div class="carousel-cell">
                        <a href="${product.url}">
                            ${picture}
                        </a>
                    </div>`;
                });
                productImagesHtml += `</div>`;
                if ($(window).width() > 1024) {
                }
                if ($(window).width() < 1024) {
                }
                productImagesHtml += `</div>`;
            }

            if (!isPopup) {
                if (product.has_special_price && categoryId) {
                    soldeTag = `<div class="mz-promo-tag-listing ${product.isEligibleToSoldeClass}">`;
                    soldeTag += `<span class=" promo-design promo-design-tag"></span>`;
                    soldeTag += `</div>`;
                }
                if (window.screen.width > 1024) {
                    quickView = `<span class="ax-quikview-btn" data-show-price-ref="${showPriceRefMenzzo}" data-show-special-offre="${showSpecialOffre}" data-hide-old-price="${hidePromoOldPrice}"  data-cat-id="${selectedCategoryId}" data-product-id='${product.product_id}' >${quickViewLabel}</span>`;
                }

                if (product.free_shipping) {
                    freeShipping = `<div class="bigBuyProd">`;
                    freeShipping += `<span class="develaryBlock">`;
                    freeShipping += `<svg class="icon sprite-svg-icon deliveryTruck">`;
                    freeShipping += `  <use xlink:href="${svgSpriteUrl}#delivery_truck"></use>`;
                    freeShipping += `</svg>`;
                    freeShipping += `<span class="bigbuy-text">${$t('Free delivery')}</span>`;
                    freeShipping += `</span>`;
                    freeShipping += `</div>`;
                }
            }

            var discountClass = "";
            var axDisplayPromo = typeof product.ax_display_promo !== "undefined" ? product.ax_display_promo : false;
            if (axDisplayPromo) {
                discountClass = "ax-special-price discount-29";
            }
            let hidePromoOldPriceForCurrentProduct = hidePromoOldPrice;
            let forcedProductIds = [15651,16418,16630,16660,16907,16702,17971,18000,18290,18304,19344,20160,20894,20945,20951,21023,21792,21806,22158,22183,22199,22229,22290,22427,22429,22864,22866,22957,22966,23123,23492,23503,23550,23563,23579,24773,24765,25144,25100,33286,26942,24954,25229,25404,25219,26797,25789,25790,25792,27214,25842,27479,27543,27438,27730,27923,27924,28401,28534,27952,28560,28217,28224,28093,28096,28103,28154,28165,28173,28665,28655,28646,28789,28714,28748,28710,29021,28996,29493,29309,29947,30049,30050,30053,30458,31356,31921,30670,32002,32047,31710,31701,31899,32182,32347,32245,32577,32588,34127,34133,34217,35971,34463,35891,34938,34900,34903,34892,34914,36414,36600,36855,36889,36893,37248,37211,37644,38526,39130,38850,38982,39654,40913,41271,41325,41931,41650,41744,42459,42782,43530,44030,45787,45627,45588,45632,45662,45663,46135,46260,46839,47627,48053,51917,48415,48801,48894,48914,48905,48922,49327,51277,49968,51273,50578,49569,50812,49483,50394,51167,50759,50339,51716,51233,51186,52980,53043,51244,50966,50721,51949,51334,52227,52234,52243,52370,52358,52214,52120,52143,52721,52723,52718,52625,52794,52524,53152,53136,53129,53811,29450,37570,38701,41388,47794,51720,19512,20977,23504,27408,27707,27778,28278,29244,30226,31694,31696,31871,31900,32311,32316,32532,43180,45789,48253,48641,49326,51868,49735,50971,51666,49553,51882,50720,52527,53832,53804, 15207,15366,17061,27769,28694,29232,36701,43170,47969,48590,50707,50829,50827,52627,15289,15852,16067,23553,23593,25118,29428,36784,39263,47864,49271,49901];


            if (!hidePromoOldPrice && product.brand != 'Menzzo' &&
                !forcedProductIds.some(id => id.toString() === product.product_id.toString())) {
                hidePromoOldPriceForCurrentProduct = 1;
            }

            var priceHtml = `<span class='price ${discountClass}'>${product.price} €</span>`;
            if (product.has_special_price) {
                if (hidePromoOldPriceForCurrentProduct == 1 || parseInt(product.discount) <= 5) {
                    if (parseInt(product.discount) >= 29 || true) {
                        discountClass = "ax-special-price discount-29";
                    }
                    priceHtml = `<span class='price ${discountClass}'>${product.special_price} €</span>`;
                } else if (hidePromoOldPriceForCurrentProduct == 0 && parseInt(product.discount) > 5) {

                    priceHtml = `<span class="line-through mr-1">${product.regular_price} €</span>`;

                    if (!(product.solde_label && product.solde_label == "PROMO" && hideDiscountPercentOnPromoTag == 1)) {
                        priceHtml += `<span class="btn-promo mr-2">-${product.discount}</span>`;
                    }

                    priceHtml += `<span class="price-promo">${product.special_price} €</span>`;
                }
            }

            var isTagProductHtml = '';
            if (product.categoriesIds.includes('417')) {
                isTagProductHtml = `
                <div class="highlighted-msg">
                    <span class="icn">⚠️</span><span>${$t('Last copies before the catalogue is withdrawn !')}</span>
                </div>
                `;
            } else if (product.categoriesIds.includes('418')) {
                isTagProductHtml = `<div class="tag-unbeatable">${$t('UNBEATABLE PRICE!')}</div>`;
            } else if (product.categoriesIds.includes('128') && product.highlighted_selection.includes('966')) {
                isTagProductHtml = `<div class="tag-new">${$t('NEW !')}</div>`;
            }

            var isBlackFridayHtml = '';
            if (typeof product.solde_label !== "undefined" && product.solde_label) {
                isBlackFridayHtml = `<div class='mz-black-fridy'>${$t(product.solde_label)}</div>`;
            }

            try {
                var typesenseImageHtml = '';
                if ($(window).width() > 1024) {
                    let firstImageUrl = (productImages && productImages.length > 0) ? productImages[0].url : null;
                    let lazySrcset = "https://cdn-img.menzzo.com/media/lazysizes/menzzo_big.jpg?twic=v1/cover=338x440";

                    if (firstImageUrl && !firstImageUrl.startsWith('data:image/')) {
                        let firstWebpUrl = firstImageUrl.includes('?') ? firstImageUrl + '&fm=webp' : firstImageUrl;
                        typesenseImageHtml = `
                <div class="ax-typesense-product-url w-100" data-ax-page-number="${product.in_page}" data-product-id="${product.product_id}">
                    <picture>
                        <source data-flickity-lazyload-srcset="${firstImageUrl}" data-srcset="${firstImageUrl}?twic=v1/resize=288" type="image/webp">
                        <img data-flickity-lazyload="${firstImageUrl}" class="typesenseFirstImage typesenseImage lazyload" srcset="${lazySrcset}" src="${lazySrcset}" alt="${product.name}" width="288" height="288"/>
                    </picture>
                </div>
            `;
                    } else {
                        typesenseImageHtml = `
                <div class="ax-typesense-product-url w-100" data-ax-page-number="${product.in_page}" data-product-id="${product.product_id}">
                    <picture>
                        <img class="typesenseFirstImage typesenseImage lazyload" src="${lazySrcset}" alt="Image not available" width="288" height="288"/>
                    </picture>
                </div>
            `;
                    }
                }

                var html = `
        <div class="typesense-product-item" id="product-${product.product_id}">
            ${quickView}
            ${soldeTag}
            ${productImagesHtml}
            <div class="mt-3 product-dispo  d-flex flex-column justify-content-start align-items-start px-3">
                <span class="${product.avail_class} mb-3">${product.dispo}</span>
                ${freeShipping}
            </div>
            <div class="mt-3 product-name  px-3">
                <a href="${product.url}" class="product-url ax-typesense-product-url w-100" data-ax-page-number="${product.in_page}" data-product-id="${product.product_id}">
                    <span>${product.name}</span>
                </a>
            </div>
            <div class="mt-auto hit-price-wrap d-flex justify-content-between align-items-center">
                 <span class="d-flex align-items-center">${priceHtml}</span>
                 ${isBlackFridayHtml}
            </div>
            <div class="ax-product-tag text-center">
                ${isTagProductHtml}
            </div>
        </div>
    `;

                return html;

            } catch (error) {
                console.error('Error generating HTML:', error);
                return '';
            }


        }


        function swiperImage(productId) {
            require(['require', 'flickity'], function (require, Flickity) {

                if ($(`.carousel-${productId}`).length) {
                    $(`.carousel-${productId}`).each(function () {
                        var flickity = new Flickity(this, {
                            pageDots: true,
                            cellAlign: 'left',
                            lazyLoad: 1,
                            imagesLoaded: true,
                            wrapAround: true,
                            contain: true
                        });

                        if (isSafari13()) {
                            $(this).find('.flickity-viewport').css('width', '288px');
                            flickity.resize()
                        }
                    })
                }
            })
        }

        function getMobileRolloverImage() {
            var elements = document.querySelectorAll('.typesense-product-item .image-container.typesenseImageContainer');
            elements.forEach((element) => {
                element.addEventListener('swiped-left', function (e) {
                    var elem = e.target;
                    if (elem.closest('.image-container.typesenseImageContainer').getElementsByClassName('typesenseSecondImage').length) {
                        elem.closest('.image-container.typesenseImageContainer').getElementsByClassName('typesenseFirstImage')[0].classList.add('hidden');
                        elem.closest('.image-container.typesenseImageContainer').getElementsByClassName('typesense-prev-bullet')[0].classList.remove('active');
                        elem.closest('.image-container.typesenseImageContainer').getElementsByClassName('typesense-next-bullet')[0].classList.add('active');
                        elem.closest('.image-container.typesenseImageContainer').getElementsByClassName('typesenseSecondImage')[0].classList.remove('hidden');
                    }
                });

                element.addEventListener('swiped-right', function (e) {
                    var elem = e.target;
                    if (elem.closest('.image-container.typesenseImageContainer').getElementsByClassName('typesenseSecondImage').length) {
                        elem.closest('.image-container.typesenseImageContainer').getElementsByClassName('typesenseSecondImage')[0].classList.add('hidden');
                        elem.closest('.image-container.typesenseImageContainer').getElementsByClassName('typesense-next-bullet')[0].classList.remove('active');
                        elem.closest('.image-container.typesenseImageContainer').getElementsByClassName('typesense-prev-bullet')[0].classList.add('active');
                        elem.closest('.image-container.typesenseImageContainer').getElementsByClassName('typesenseFirstImage')[0].classList.remove('hidden');
                    }
                });
            })
        }

        function getDesktopRolloverImage() {

            $(document).on("mouseenter", '.search-page .typesense-product-item .image-container.typesenseImageContainer', function (e) {
                if ($(this).find('.typesenseSecondImage').length) {
                    $(this).find('.typesenseFirstImage').addClass('hidden');
                    $(this).find('.typesenseSecondImage').removeClass('hidden');
                }

            });
            $(document).on("mouseleave", '.search-page .typesense-product-item .image-container.typesenseImageContainer', function (e) {
                if ($(this).find('.typesenseSecondImage').length) {
                    $(this).find('.typesenseSecondImage').addClass('hidden');
                    $(this).find('.typesenseFirstImage').removeClass('hidden');
                }
            });


        }

        const resizingSelect = document.querySelector("#sort-by select");
        const helperElement = document.querySelector(".helper-element");
        if (resizingSelect && helperElement) {
            resizingSelect.addEventListener("change", initResize);
        }

        function initResize(event) {
            if (helperElement) {
                helperElement.innerHTML = event.target.querySelector(
                    "option:checked"
                ).innerText;
                resize(helperElement.offsetWidth);
            }
        }

        function resize(width) {
            const root = document.documentElement;
            root.style.setProperty("--dynamic-size", `${width}px`);
        }

        function scrollToElement(selector) {
            $(selector)[0].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            });
        }

        function getQuerySuggestions(query, refine) {
            try {
                var limit = 6;
                if (query.length == 0) {
                    query = '*';
                }
                var settings = {
                    "url": "https://" + typesenseConfig.host_name + "/multi_search?query_by=q",
                    "method": "POST",
                    "timeout": 0,
                    "headers": {
                        "Content-Type": "application/json",
                        "X-TYPESENSE-API-KEY": typesenseConfig.api_key
                    },
                    "data": JSON.stringify({
                        "searches": [
                            {
                                "collection": collectionNameQueryAnalytics,
                                "q": query,
                                "query_by": "q",
                                "sort_by": "count:desc",
                                "limit": limit,
                                "highlight_full_fields": 'q'
                            }
                        ]
                    }),
                };
                $.ajax(settings).done(function (response) {
                    var autocompleteTypesense = $('#search_popup-autocomplete');
                    if ($(window).width() < 768) {
                        autocompleteTypesense = $('#search_popup-autocomplete-mobile');
                    }
                    var result = typeof response.results != "undefined" ? response.results[0]['hits'] : [];
                    autocompleteTypesense.html('');
                    if (result.length) {
                        result = result.filter(item => item.document.q !== "*");
                        result = result.slice(0, 5);
                        var html = '<ul>';
                        result.forEach((res) => {
                            var resultText = Object.keys(res.highlight).length > 0 && typeof res.highlight.q.snippet != "undefined" ? res.highlight.q.snippet : res.document.q;
                            // console.log(resultText);
                            html += `<li class="input-query">${resultText}</li>`;
                        });
                        html += '</ul>';
                        autocompleteTypesense.html(html);
                    } else {
                        getQuerySuggestions('', refine);
                    }
                });
                $(document).on('click', '.input-query', function (e) {
                    var value = $(this).text();
                    $('#searchbox .ais-SearchBox .field.search').find('.ais-SearchBox-input').val(value);
                    $('#searchbox .ais-SearchBox .field.search').find('.ais-SearchBox-input').focus();
                    refine(value);
                    // if ($(window).width() < 768) {
                    //     $(this).closest('#search_popup-autocomplete-mobile').html('');
                    // }
                });
                // Function to compare two sets of facetsStats
            } catch (error) {
                console.log(error);
            }
        }
        function compareFacetsStats(facetsStats1, facetsStats2) {
            // Convert facetsStats objects to JSON strings for comparison
            const json1 = JSON.stringify(facetsStats1);
            const json2 = JSON.stringify(facetsStats2);

            // Return true if the JSON strings are different, false otherwise
            return json1 !== json2;
        }

        $(document).on('click', '#btn-typesense-filter', function (e) {
            e.preventDefault(); // Prevent default behavior of the click event

            var options = {
                autoOpen: false,
                innerScroll: true,
                responsive: true,
                modalClass: "all-filter-popup-wrapper",
                type: 'popup'
            };

            var typesenseFiltersContent = $('.typesense-filters').html();
            modal(options, $('#filter-popup-wrapper'));
            $('#filter-popup-wrapper').modal("openModal");
            $('#filter-popup-wrapper .typesense-filters').removeClass("hidden");

            // Show dropdown-filter-content with checked inputs
            $('#filter-popup-wrapper .dropdown-filter-content').each(function () {
                $(this).toggleClass("show", $(this).find('input:checked').length > 0);
            });

            // Update dropdown-filter-content visibility on input change
            $('#filter-popup-wrapper').on('change', 'input', function () {
                var dropdownFilterContent = $(this).closest('.dropdown-filter-content');
                dropdownFilterContent.toggleClass('show', dropdownFilterContent.find('input:checked').length > 0);
            });

            $('.rheostat-handle').each(function () {
                var $this = $(this);
                // Update show class based on initial values
                var currentValue = parseInt($this.attr('aria-valuenow'));
                var maxValue = parseInt($this.attr('aria-valuemax'));
                var minValue = parseInt($this.attr('aria-valuemin'));

                if (currentValue !== maxValue && currentValue !== minValue) {
                    $this.closest('#filter-popup-wrapper .dropdown-filter-content').addClass('show');
                }

                // Event listeners for slider interactions
                $this.on('mousedown touchstart', function (event) {
                    $(document).on('mousemove.slider touchmove.slider', function () {
                        var newValue = parseInt($this.attr('aria-valuenow'));
                        if (newValue !== maxValue && newValue !== minValue) {
                            $this.closest('#filter-popup-wrapper .dropdown-filter-content').addClass('show');
                        }
                    });

                    $(document).on('mouseup.slider touchend.slider', function () {
                        $(document).off('.slider');
                    });

                    // Prevent default touch action
                    event.preventDefault();
                });
            });

        });
        $(document).on('click', '#mobile-typesense-filter', function (e) {
            e.preventDefault(); // Prevent default behavior of the click event
            $('.rheostat-handle').each(function () {
                var $this = $(this);
                // Update show class based on initial values
                var currentValue = parseInt($this.attr('aria-valuenow'));
                var maxValue = parseInt($this.attr('aria-valuemax'));
                var minValue = parseInt($this.attr('aria-valuemin'));

                if (currentValue !== maxValue && currentValue !== minValue) {
                    $this.closest('#filter-popup-wrapper .dropdown-filter-content').addClass('show');
                }

                // Event listeners for slider interactions
                $this.on('mousedown touchstart', function (event) {
                    $(document).on('mousemove.slider touchmove.slider', function () {
                        var newValue = parseInt($this.attr('aria-valuenow'));
                        if (newValue !== maxValue && newValue !== minValue) {
                            $this.closest('#filter-popup-wrapper .dropdown-filter-content').addClass('show');
                        }
                    });

                    $(document).on('mouseup.slider touchend.slider', function () {
                        $(document).off('.slider');
                    });

                    // Prevent default touch action
                    event.preventDefault();
                });
            });
        });

        $(document).ready(function() {
            if (isPopup &&  ($(window).width() > 767)) {
                var searchInput = $('.ais-SearchBox-input');
                searchInput.on('input', function() {
                    var searchTerm = $(this).val();
                    if (searchTerm === '') {
                        search.helper.clearRefinements().search();
                    }
                    else {
                        $('.container-search').css('display', 'flex');
                    }
                });
            }
        });

        $(document).ready(function () {
            var dropdownButtons = $(".dropdown-filter .dropbtn");
            var dropdownContents = $(".dropdown-filter-content");
            var searchInputs = $(".ais-SearchBox-input");

            dropdownButtons.click(function () {
                var button = $(this);
                dropdownContents.each(function () {
                    if (!$(this).is(button.next())) {
                        $(this).removeClass("show");
                    }
                });
                button.next().toggleClass("show");
            });

            $(window).click(function (event) {
                if (!$(event.target).closest('.dropbtn') && !$(event.target).closest('.ais-SearchBox-input')) {
                    dropdownContents.removeClass("show");
                }
            });

            searchInputs.click(function (event) {
                event.stopPropagation();
            });

            $('#sort-by select').change(function() {
                var selectedOption = $(this).val();
                if (selectedOption === collectionName) {
                    $(this).css('width', 'var(--select-size)');
                } else {
                    $(this).css('width', 'auto');
                }
            });


        });

        function initializeFilters() {
            attachFilterEventListeners();
            attachDropdownEventListeners();
        }
        function attachDropdownEventListeners() {
            $(document).off('click', '.dropdown-filter .dropbtn');
            $(document).off('click', function (event) {

            });
            $(document).off('click', '.ais-SearchBox-input');

            $(document).on('click', '.dropdown-filter .dropbtn', function (e) {
                e.preventDefault();
                console.log('Dropdown button clicked');
                var button = $(this);
                var dropdownContent = button.next('.dropdown-filter-content');

                $('.dropdown-filter-content').not(dropdownContent).removeClass("show");

                dropdownContent.toggleClass("show");
            });

            $(document).on('click', function (event) {
                if (!$(event.target).closest('.dropdown-filter').length) {
                    console.log('Window clicked, hiding dropdowns');
                    $('.dropdown-filter-content').removeClass("show");
                }
            });

            $('.ais-SearchBox-input').on('click', function (event) {
                event.stopPropagation();
                console.log('Search box input clicked');
            });

        }
        function attachFilterEventListeners() {
            console.log('Attaching filter event listeners');

            $(document).on('click', '.filter-title-mobile', function (e) {
                e.preventDefault();
                console.log('Filter title mobile clicked');
                $('#mobile-typesense-filter').trigger('click');
            });

            $(document).on('click', '#apply-typesense-filter', function (e) {
                e.preventDefault();
                console.log('Apply filter clicked');
                if (isPopup) {
                    $('body').removeClass('has-modal');
                }
                $(cssClassPrefix + '.typesense-filters').css('display', 'none');
                $(cssClassPrefix + '.typesense-filters-top').css('display', 'none');
                $(cssClassPrefix + '.typesense-sorting-results').css('display', 'block');
                if ($(cssClassPrefix + ' .stats-wrap').get(0)) {
                    $(cssClassPrefix + ' .stats-wrap').get(0).scrollIntoView({
                        behavior: 'auto',
                        block: 'center',
                        inline: 'center'
                    });
                }
            });

            $(document).on('click', '#close-typesense-filter', function (e) {
                e.preventDefault();
                console.log('Close filter clicked');
                $(cssClassPrefix + '.ais-ClearRefinements-button').not('.ais-ClearRefinements-button--disabled').trigger('click');
                $(cssClassPrefix + '.typesense-filters').css('display', 'none');
                $(cssClassPrefix + '.typesense-filters-top').css('display', 'none');
                $(cssClassPrefix + '.typesense-sorting-results').css('display', 'block');
                $('#html-body').removeClass('no-scroll');
                if (isPopup) {
                    $('body').removeClass('has-modal');
                }
            });
            attachDropdownEventListeners();
        }
        function isSafari13() {
            var ua = navigator.userAgent.toLowerCase();

            // Check if the browser is Safari
            var isSafari = /^((?!chrome|android).)*safari/i.test(ua);

            if (isSafari) {
                // Extract version information for Safari
                var match = ua.match(/version\/(\d+)\./);
                if (match && parseInt(match[1]) === 13) {
                    return true;  // It's Safari 13
                }
            }
            return false;
        }
    }
});

